/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
    Component,
    OnInit,
    ViewChild,
    EventEmitter,
    Output,
} from '@angular/core';
import { RegistrationForm } from 'src/app/shared/forms/registration.form';
import APP_CONFIG from 'src/app/config/app.config';
import { LangService } from 'src/app/services/lang.service';
import { UserService } from 'src/app/services/user.service';
import { RegistrationInterface } from 'src/app/interfaces/registration.interface';
import { TranslateService } from '@ngx-translate/core';

import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthetificationDialogComponent } from '../authetification/authetification.component';
import { CountryInterface } from '../../../interfaces/country.interface';
import { CountryService } from '../../../services/country.service';

import { WindowRefService } from '@/shared/window-ref';
import { SnackBarService } from '@/services/snack-bar.service';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
})
export class RegistrationComponent implements OnInit {
    @ViewChild('captchaElem', { static: true })
    public captcha: any;

    @Output()
    private onChange: EventEmitter<string> = new EventEmitter();

    public siteKey: string = APP_CONFIG.recaptchaSiteKey;

    public recaptchaTheme: string = 'light';

    public formLoaded: boolean = false;

    public submitText: string = 'submit_form';

    public countries: CountryInterface[] | null;

    /**
     * True in case of success registration
     */
    public registrationSuccess: boolean = false;

    public smsRegistration: boolean = false;

    public constructor(
        public dialogRef: MatDialogRef<AuthetificationDialogComponent>,
        private router: Router,
        public regForm: RegistrationForm,
        public lang: LangService,
        private user: UserService,
        private translate: TranslateService,
        private countryService: CountryService,
        private snackbar: SnackBarService,
        private win: WindowRefService,
    ) {}

    public ngOnInit(): void {
        setTimeout((): void => this.resetForm(false), 200);

        if (this.countryService.isLoaded) {
            this.countries = this.countryService.countries;
        } else {
            this.countryService.load();
            this.countryService.onLoad.subscribe((): any => {
                this.countries = this.countryService.countries;
            });
        }
    }


    public handleLoadRC(): void {
        this.formLoaded = true;
    }

    public handleSuccessRC(token: any): void {
        this.regForm.patchValue({
            recaptcha: token,
        });
    }

    /**
     * Register user
     * @return {void}
     */
    public save(): void {
        this.submitText = 'form_loading_text';
        this.regForm.getGroup().disable();
        this.user.makeRegistration<RegistrationInterface>(
            this.regForm.getGroup().value,
        )
            .subscribe(
                (resp: Record<string, any>): void => {
                    this.win.nativeWindow.dataLayer.push({
                        event: 'sign_up',
                        email: this.regForm?.email?.value,
                        type: 'manual',
                    });

                    this.resetForm();


                    this.registrationSuccess = true;
                    this.smsRegistration = resp.sms_registration;

                    if (resp.status && resp.sms_registration) {
                        this.dialogRef.close();
                        // this.router.navigate(['verify-phone-user']);
                    } else if (resp.status) {
                        this.dialogRef.close();
                        this.router.navigate(['page/blk/registration-confirm']);
                    }
                },
                (er): void => {
                    this.regForm.getGroup().enable();
                    this.captcha.resetCaptcha();
                    this.submitText = 'submit_form';
                    this.regForm.setErrors(er);
                },
            );
    }

    /**
     * Reset forn
     */
    public resetForm(resetCaptcha: boolean = true): void {
        this.regForm.getGroup().enable();
        this.regForm.getGroup().reset();
        if (resetCaptcha) { this.captcha.resetCaptcha(); }
        this.submitText = 'submit_form';
        this.registrationSuccess = false;
    }

    private openSnackBar(message: string, action: 'info' | 'alert'): void {
        this.snackbar.openSnackBar(message, 'top', action);
    }

    /**
     * Emit action
     * @param  {string} type
     * @return {void}
     */
    public action(type: string): void {
        this.onChange.emit(type);
        this.registrationSuccess = false;
    }

    public change(type: string): void {
        if (type === 'close') {
            this.translate.get('web.sign_in_success').subscribe((): void => {
                this.openSnackBar(
                    this.translate.instant('web.sign_in_success'),
                    'info',
                );
            });
            this.onChange.emit('close');
        }
    }

    public toggleUsePhoneNumber(): void {
        this.regForm.email!.clearValidators();
        this.regForm.phoneNumber!.clearValidators();
        this.regForm.phoneNumberCode!.clearValidators();

        this.regForm.usePhoneNumber!.patchValue(!this.regForm.usePhoneNumber!.value);

        if (this.regForm.usePhoneNumber!.value) {
            this.regForm.phoneNumberCode!.setValidators([Validators.required]);
            this.regForm.phoneNumber!.setValidators([Validators.required]);
        } else {
            this.regForm.email!.setValidators([
                Validators.required,
                Validators.email,
            ]);
        }

        this.regForm.email!.updateValueAndValidity();
        this.regForm.phoneNumberCode!.updateValueAndValidity();
        this.regForm.phoneNumber!.updateValueAndValidity();
    }
}
