/**
 *  24live configuration
 *
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { StorageService } from '@services/storage.service';
import { ClientGuard } from '@interfaces/client.interface';
import APP_CONFIG from '@config/app.config';

import { LANG } from '@/interfaces/lang.interface';

const storage: StorageService = new StorageService();
const isDarkMode = !!(storage.get(APP_CONFIG.switchStickyKeyPageMode) ?? false);
const langIso = (storage.get('lang') ?? APP_CONFIG.defaultLang);


const config: ClientGuard = {
    active: {
        signin: true,
        social: true,
        cookie: true,
        pagesInternal: true,
        oddsTab: true,
        share: true,
        closeIcon: false,
        winProbability: true,
        matchListAlpha: true,
        sportQuickNav: true,
        desktopLogo: true,
        pageDarkMode: true,
        betsStats: true,
        prematchH2HDefault: false,
        liveIcon: true,
        channels: true,
        news: true,
        video: true,
        map: true,
        canonicalPartner: true,
        partners: true,
        opta: true,
        useDefaultLogo: true,
        matchDetailStatistics: true,
        matchDetailPeriodStats: true,
        matchDetailLeagueTable: true,
        matchDetailPlayers: true,
        matchDetailH2H: true,
        matchDetailCuptree: true,
        matchDetailTopPlayers: true,
        matchDetailMatchActions: true,
        matchDetailCommentary: true,
        matchDetailForm: true,
        matchDetailProbability: true,
        matchDetailChannels: true,
        matchDetailEvents: true,
        matchDetailBetting: true,
        matchDetailFunFacts: true,
        seoCard: true,
        notification: true,
        tracker: true,
    },
    social: {
        facebook: langIso === LANG.CS ? 'https://www.facebook.com/24live.scorestats'
            : 'https://www.facebook.com/24liveresults',
        instagram: 'https://www.instagram.com/24liveresults',
        youtube: 'https://www.youtube.com/channel/UCQQklw0bb9ndGCYJc2pJ5Ng?view_as=subscriber',
        linkedin: 'https://www.linkedin.com/company/24liveresults/ ',
        microsite: 'https://score-stats.com',
        tiktok: 'https://www.tiktok.com/@24liveglobal',
        blog: 'https://24live.com/blog',
        telegram: 'https://t.me/catcheverymoment',

    },
    bet: {
        isOddsLiveAndAfter: true,
    },
    colors: {
        icons: isDarkMode ? 'white' : 'black',
    },
};

export default config;
