
import { DeviceDetectorService } from 'ngx-device-detector';

import { ChangeDetectorRef } from '@angular/core';

import { AdsInterface } from '@interfaces/ads.interface';

import $ from 'jquery';

import { AdsService } from '@services/ads.service';

import { BaseComponent } from '../base.component';


/**
 *  Abstract class
 *  @author Livescore <it@24live.com>
 *  @copyright 2024 livescore
 */

export abstract class AdsBase extends BaseComponent {
    public ads: AdsInterface[] | null = null;

    private isFirst: boolean = false;


    public constructor(public deviceService: DeviceDetectorService, protected ref: ChangeDetectorRef) {
        super();
    }

    public setAds(ads: AdsInterface[] | null): void {
        this.ads = ads;

        this.ads?.forEach((add: AdsInterface) => {
            if ('generalCode' in add && !AdsService.platformIds.includes(add.platformId)) {
                $('head').append(add.generalCode);
            }

            if ('inlineCode' in add) {
                setTimeout(() => {
                    const elm = document.getElementById(add.pageElementId);
                    if (!elm) { return; }
                    if (!this.isFirst) {
                        if (add.scriptTag === 'yes') {
                            elm!.innerHTML = add.inlineCode;

                            if (add.platformName === 'Google AdSense') {
                                setTimeout(() => {
                                // eslint-disable-next-line no-eval
                                    eval('(window.adsbygoogle = window.adsbygoogle || []).push({})');
                                }, 1000);
                            }

                            if (add.platformName === 'Ezoic') {
                                setTimeout(() => {
                                // eslint-disable-next-line no-eval, max-len
                                    eval(`ezstandalone.cmd.push(function() { ezstandalone.showAds(${add.pageElementId.replace('ezoic-', '')});});`);
                                }, 1000);
                            }
                        } else {
                        // eslint-disable-next-line no-eval
                            eval(add.inlineCode);
                        }
                    }
                    this.isFirst = true;
                }, 500);
            }

            AdsService.platformIds.push(add.platformId);
        });

        this.ref.markForCheck();
    }

    public trackById(index: number, item: Record<string, any>): number {
        return item.id;
    }
}
