/**
 *  Snack bar
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2025 livescore
 */

import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    private timeout: any;

    public constructor() {}


    public openFromComponent(viewRef: ViewContainerRef, component: any, options:{
        duration: number;
        verticalPosition: 'bottom' | 'top'
        horizontalPosition: 'center' | 'left' | 'right',
        data: Record<string, any>,
    }):void {
        viewRef.clear();
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        const compRef = viewRef.createComponent(component);

        if (compRef) {
            (<any>compRef.instance).data = options.data;
        }

        this.timeout = setTimeout(() => {
            viewRef.clear();
        }, options.duration);
    }


    /**
     * Chekc if soudn is off
     * @return {boolean}
     */
    public openSnackBar(message: string, position: 'top' | 'bottom' = 'top',
        action: 'info' | 'alert' = 'info', timeout: number = 5000): void {
        const elm = document.getElementsByClassName('livescore-snackbar')[0] as HTMLElement;
        if (elm) {
            elm.innerHTML = `<i class="fas fa-comment-dots"></i> ${message}`;
            elm.classList.add('show');

            if (position === 'top') {
                elm.classList.add('--top');
            } else {
                elm.classList.add('--bottom');
            }

            if (action === 'info') {
                elm.classList.add('--info');
            }
            if (action === 'alert') {
                elm.classList.add('--alert');
            }
            setTimeout(() => {
                this.reset(elm);
            }, timeout);
        }
    }

    public dismiss(viewRef: ViewContainerRef): void {
        viewRef.clear();
    }

    private reset(elm: HTMLElement): void {
        elm.classList.remove('show');
        elm.classList.remove('--top');
        elm.classList.remove('--bottom');
        elm.classList.remove('--info');
    }
}
