<header class="mt-lg-5 app-main-header">
    <!-- Adds header mobile + desktop-->
    <ng-container *ngIf="!isMobile">
        <app-ads-container position="desktop_header" [platforms]="['desktop']">
            <app-ads desktop-header #ads></app-ads>
        </app-ads-container>
    </ng-container>
    <ng-container *ngIf="isMobile">
        <app-ads-container position="mobile_header" [platforms]="['mobile']">
            <app-ads mobile-header #ads></app-ads>
        </app-ads-container>
    </ng-container>
    <app-banner-container>
        <app-banner mobile-header #banners></app-banner>
    </app-banner-container>

    <nav class="navbar navbar-dark navbar-expand-lg" [class.event-page]="isEventPage">
        <div class="container header-container bg-secondary-new p-2 px-lg-0 position-relative">
            <button
                [class.d-none]="hasBackButton"
                class="navbar-toggler text-left"
                type="button"
                data-toggle="collapse"
                (click)="openNavBarMobile()"
                aria-controls="navbar-mobile"
                aria-expanded="false"
                aria-label="Zapnout navigaci"
            >
                <svg class="icon icon--28 icon--white"><use xlink:href="#icon-menu" /></svg>

                <img
                    *ngIf="fullMenu && sportName"
                    src="{{ cdn }}/img/sports/{{ sportName }}.png"
                    alt="Fotbal"
                    class="ml-1 d-none d-sm-inline"
                    height="28"
                />
                <div class="small-xs text-white text-uppercase mt-1">{{ 'web.menu' | translate | transinit }}</div>
            </button>
            <button
                class="navbar-toggler mobile-back-button"
                *ngIf="hasBackButton"
                type="button"
                data-toggle="collapse"
                (click)="goBack()"
            >
                <i class="fas fa-chevron-left"></i>
            </button>

            <ng-container *ngIf="client.use('desktopLogo')">
                <ng-container *ngIf="client.isParentBetting; else noBetting">
                    <a class="navbar-brand px-2 px-md-3 mx-0 d-none d-lg-inline-block" [href]="client.getParentDomain()"
                        ><img
                            [src]="cdn + '/img/' + client.getName() + '.png?v4'"
                            [alt]="client.getName()"
                            class="app-logo"
                    /></a>
                </ng-container>
                <ng-template #noBetting>
                    <ng-container *ngIf="!client.use('closeIcon'); else closeIcon">
                        <a
                            class="navbar-brand px-2 px-md-3 mx-0 d-none d-lg-inline-block"
                            [routerLink]="['/hp']"
                            (click)="resetNav($event, true)"
                            [class.app-nonvisible]="!client.clientLoaded"
                        >
                            <img
                                [src]="cdn + '/img/' + client.getName() + '.png?v4'"
                                [alt]="client.getName()"
                                class="app-logo"
                            />
                        </a>
                    </ng-container>
                    <ng-template #closeIcon>
                        <a
                            class="navbar-brand px-2 px-md-3 mx-0 d-none d-lg-inline-block app-cursor"
                            (click)="closeModal()"
                            ><img
                                [src]="cdn + '/img/' + client.getName() + '.png?v2'"
                                [alt]="client.getName()"
                                [class.app-nonvisible]="!client.clientLoaded"
                                class="app-logo"
                        /></a>
                    </ng-template>
                </ng-template>
            </ng-container>

            <button
                [class.d-none]="!fullMenu"
                class="btn btn-link px-1 d-lg-none navbar-dropdown-competitions"
                [class.app-state-color-active]="lastUrl == 'event'"
                role="button"
                data-toggle="dropdown"
                (click)="resetNav($event)"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <svg class="icon icon--24 icon--white"><use xlink:href="#icon-cup" /></svg>
                <div class="small-xs text-white text-uppercase mt-2">
                    {{ 'web.competitions' | translate | transinit }}
                </div>
            </button>

            <a
                [class.d-none]="!fullMenu || !sportName"
                class="btn btn-link px-1 d-lg-none"
                [class.app-state-color-active]="lastUrl == 'match-list'"
                [routerLink]="eventListLink.concat([sportName + '-' + sportId])"
                (click)="resetNav($event, true)"
            >
                <svg class="icon icon--24 icon--white">
                    <use [attr.xlink:href]="'#icon-' + sportName" />
                </svg>
                <div class="small-xs text-white text-uppercase mt-2">
                    {{ 'sport.' + sportName | translate | transinit | truncate : 8 }}
                </div>
            </a>

            <img
                *ngIf="!fullMenu"
                [src]="cdn + '/img/' + client.getName() + '.png'"
                alt="{{ client.getName() }}"
                (click)="goToHp()"
                class="app-mobile-hp-logo"
            />

            <a
                class="btn btn-link px-1 d-lg-none"
                [routerLink]="['/page/sport', 'my-matches']"
                [class.app-state-color-active]="lastUrl == 'my-matches'"
                (click)="resetNav($event, true)"
            >
                <svg class="icon icon--24 icon--white"><use xlink:href="#icon-star" /></svg>
                <small style="position: absolute"
                    ><span class="badge badge-primary badge__count badge-custom--mobile">{{
                        favorite.getNumMatches()
                    }}</span></small
                >
                <div class="small-xs app-no-wrap text-white text-uppercase mt-2">
                    {{ 'web.my_matches_num' | translate | transinit }}
                </div>
            </a>

            <button
                [class.d-none]="!fullMenu"
                data-cy="search-button"
                class="btn btn-link px-2 d-lg-none"
                data-toggle="modal"
                (click)="openSearchDialog()"
                data-target="#modal-search"
            >
                <svg class="icon icon--24 icon--white"><use xlink:href="#icon-search" /></svg>
                <div class="small-xs text-white text-uppercase mt-2">{{ 'web.search' | translate | transinit }}</div>
            </button>
            <!--
      <div class="d-inline-block d-md-none mr-3">
        <button class="btn btn-link px-0" data-toggle="modal" data-target="#modal-login"><svg class="icon icon--24 icon--white"><use href="#icon-user"></svg></button>
        <button class="btn btn-link pl-3 pr-0" data-toggle="modal" data-target="#modal-search"><svg class="icon icon--24 icon--white"><use href="#icon-search"></svg></button>
      </div>
      -->

            <div class="collapse navbar-collapse" id="navbars">
                <ul class="navbar-nav navbar-left">
                    <li class="nav-item dropdown text-uppercase" *ngIf="sportMenu">
                        <a
                            class="nav-link nav-link--arrow"
                            href="#"
                            id="navbar-dropdown-sport"
                            role="button"
                            (click)="resetNav($event, true)"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <svg class="icon icon--24 icon--white mr-2"><use xlink:href="#icon-menu" /></svg>
                            <h1 class="header-sport-headline" *ngIf="sportName">
                                {{ 'sport.' + sportName | translate | transinit }}
                            </h1>
                            <span *ngIf="!sportName">{{ 'web.choose_sport' | translate | transinit }} </span>
                        </a>
                        <div
                            class="dropdown-menu"
                            aria-labelledby="navbar-dropdown-sport"
                            data-cy="navbar-dropdown-sport"
                        >
                            <app-sport-navigation [date]="date"></app-sport-navigation>
                        </div>
                    </li>
                    <li
                        class="nav-item dropdown position-static dropdown--megamenu"
                        id="navbar-dropdown-competitions-parent"
                        *ngIf="fullMenu && showCompetitionsMenu"
                    >
                        <a
                            class="nav-link nav-link--arrow text-uppercase navbar-dropdown-competitions"
                            [class.app-state-color-active]="lastUrl == 'event'"
                            [class.nav-link--active]="lastUrl == 'event'"
                            id="navbar-dropdown-competitions"
                            (click)="resetNav($event)"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <svg class="icon icon--24 icon--white mr-2"><use xlink:href="#icon-cup" /></svg
                            >{{ 'web.competitions' | translate | transinit }}
                        </a>
                    </li>
                    <li class="nav-item dropdown text-uppercase nav-item--line" *ngIf="fullMenu && sportName">
                        <a
                            class="nav-link nav-link--line"
                            [class.app-state-color-active]="lastUrl == 'match-list'"
                            [class.nav-link--active]="lastUrl == 'match-list'"
                            (click)="resetNav($event, true)"
                            [routerLink]="eventListLink.concat([sportName + '-' + sportId])"
                        >
                            <svg class="icon icon--24 icon--white mr-2">
                                <use [attr.xlink:href]="'#icon-' + sportName" /></svg
                            >{{ 'web.' + eventType | translate | transinit }}
                        </a>
                    </li>
                    <li class="nav-item dropdown text-uppercase nav-item--line" *ngIf="client.isParentBetting">
                        <a class="nav-link nav-link--line" [href]="client.getParentDomain()">
                            <svg
                                class="icon icon--24 icon--white mr-2"
                                style="transform: rotate(180deg)"
                                *ngIf="!client.hasBetsImg(); else betImage"
                            >
                                <use xlink:href="#icon-net" />
                            </svg>
                            <ng-template #betImage>
                                <img
                                    [src]="client.hasBetsImg()"
                                    alt="{{ 'web.betting_site' | translate }}"
                                    height="22"
                                />
                            </ng-template>
                            <ng-container *ngIf="client.hasBetsText(); else betText">
                                {{ client.hasBetsText() | translate }}
                            </ng-container>
                            <ng-template #betText>
                                {{ 'web.betting_site' | translate }}
                            </ng-template>
                        </a>
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto text-uppercase navbar-right">
                    <li class="nav-item" [class.app-state-color-active]="lastUrl == 'my-matches'">
                        <app-my-matches-navigation-widget
                            data-cy="navigation-my-matches"
                            (onClick)="resetNav($event, true)"
                        ></app-my-matches-navigation-widget>
                    </li>
                    <li class="nav-item">
                        <button
                            type="button"
                            data-cy="search-button2"
                            class="btn btn-link nav-link text-white"
                            (click)="openSearchDialog()"
                        >
                            <svg class="icon icon--24 icon--white d-block mx-auto mb-1">
                                <use xlink:href="#icon-search" /></svg
                            >{{ 'web.search' | translate | transinit }}
                        </button>
                    </li>

                    <li class="nav-item d-flex" [ngClass]="userLoggedIn">
                        <app-info-widget class="d-flex" [signOffBtn]="false"></app-info-widget>
                    </li>
                    <li class="nav-item" *ngIf="client.use('closeIcon')">
                        <button
                            type="button"
                            data-cy="search-button2"
                            class="btn btn-link nav-link text-white"
                            (click)="closeModal()"
                        >
                            <i class="far fa-times-circle d-block mb-1" style="font-size: 26px"></i>
                            {{ 'web.close_modal' | translate | transinit }}
                        </button>
                    </li>
                    <!-- <li *ngIf="isLoggedIn$ | async as isLoggedIn" class="nav-item nav-item--noborder">
                        <app-info-widget [signOffBtn]="true"></app-info-widget>
                    </li> -->
                </ul>
            </div>
            <app-header-navigation
                *ngIf="firstLoadNav && fullMenu"
                [sportId]="sportId"
                [sportName]="sportName"
            ></app-header-navigation>
        </div>
    </nav>

    <div *ngIf="fullMenu && !hideFavNav" class="container px-0">
        <div class="nav-matches">
            <ng-container *ngIf="client.has('pages', 'footerText')">
                <div class="text-center">{{ client.has('pages', 'footerText') }}</div>
            </ng-container>
            <app-navigation
                (onload)="onNavLoad($event)"
                [level]="2"
                [sportId]="sportId"
                [sportName]="sportName"
                [type]="'top'"
            ></app-navigation>
        </div>
        <app-banner-container *ngIf="!deviceService.isMobile()">
            <app-banner desktop-header #banners></app-banner>
        </app-banner-container>
    </div>

    <div class="d-block d-lg-none navbar-mobile" id="navbar-mobile" [class.show]="isMobileNavOpen">
        <div class="navbar-mobile__header d-flex align-items-center">
            <button class="btn btn-link navbar-mobile__close" (click)="closeNavBarMobile()">
                <svg class="icon icon--28 icon--white"><use xlink:href="#icon-arrow-left" /></svg>
            </button>
            <div class="text-center mx-auto">
                <ng-container *ngIf="!client.isParentBetting">
                    <img
                        [src]="cdn + '/img/' + client.getName() + '.png'"
                        alt="{{ client.getName() }}"
                        (click)="goToHp()"
                        class="m-2 navbar-brand"
                    />
                </ng-container>
                <ng-container *ngIf="client.isParentBetting">
                    <a [href]="client.getParentDomain()">
                        <img
                            [src]="cdn + '/img/' + client.getName() + '.png'"
                            alt="{{ client.getName() }}"
                            class="m-2 navbar-brand"
                        />
                    </a>
                </ng-container>
            </div>
            <app-lang *ngIf="deviceService.isMobile()" [mobile]="deviceService.isMobile()"></app-lang>
            <app-info-widget
                [mobile]="true"
                *ngIf="deviceService.isMobile()"
                (action)="closeNavBarMobile()"
            ></app-info-widget>
        </div>

        <ul class="nav nav-tabs navbar-mobile__tabs nav-fill" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    id="mob_nav_sports-tab"
                    data-toggle="tab"
                    href="#mob_nav_sports"
                    role="tab"
                    aria-controls="mob_nav_sports"
                    aria-selected="false"
                    ><svg class="icon icon--white mr-2"><use href="#icon-cup" /></svg
                    >{{ 'web.sport_menu' | translate }}</a
                >
            </li>

            <li class="nav-item" *ngIf="!loginStatus && client.use('signin')">
                <a
                    class="nav-link"
                    id="mob_nav_login-tab"
                    data-toggle="tab"
                    href="#mob_nav_login"
                    role="tab"
                    aria-controls="mob_nav_login"
                    aria-selected="false"
                    ><svg class="icon icon--white mr-2"><use href="#icon-user" /></svg>{{ 'web.log_in' | translate }}</a
                >
            </li>

            <li class="nav-item" *ngIf="loginStatus">
                <a
                    class="nav-link"
                    id="settings-tab"
                    data-toggle="tab"
                    (click)="closeNavBarMobile()"
                    routerLink="/page/account/dashboard"
                    role="tab"
                    aria-controls="settings"
                    aria-selected="false"
                    ><svg class="icon icon--white mr-2"><use href="#icon-gear" /></svg
                    >{{ 'web.user_account' | translate }}</a
                >
            </li>
        </ul>

        <div class="tab-content bg-white" *ngIf="isMobilMenuAvailable">
            <div class="tab-pane fade show active" id="mob_nav_sports" role="tabpanel" aria-labelledby="mob_nav_-tab">
                <app-sport-navigation
                    [type]="'mobile'"
                    [date]="date"
                    (action)="closeNavBarMobile()"
                ></app-sport-navigation>
            </div>
            <div
                *ngIf="client.use('signin')"
                class="tab-pane fade text-secondary"
                id="mob_nav_login"
                role="tabpanel"
                aria-labelledby="mob_nav_login-tab"
            >
                <app-signin [mobile]="true" (onChange)="mobileSign($event)"></app-signin>
            </div>
        </div>
    </div>
</header>
