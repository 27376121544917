import {
    Component,
    OnInit,
    OnDestroy,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Subscription, of, Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as _ from 'underscore';
import * as $ from 'jquery';
import $$ from 'src/app/shared/jquery';
import { NavCompetitionService } from 'src/app/services/nav-competitions.service';

import { DeviceDetectorService } from 'ngx-device-detector';

import { SwiperComponent } from 'swiper/angular';

import { SwiperOptions } from 'swiper';

import { NavigationService } from '../../services/navigation.service';
import {
    NaviLevel1Interface,
    NaviLevel2Interface,
} from '../../interfaces/navigation.interface';
import { FavoriteService } from '../../services/favorite.service';
import { BaseComponent } from '../base.component';
import SPORT_CONFIG from '../../config/sport.config';

declare const moment: any;


@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.less'],
    styles: [
        '.swiper-container {width: 100%;height: 100%;}.swiper-slide {width: 15%;}',
    ],
})
export class NavigationComponent extends BaseComponent
    implements OnInit, OnDestroy, OnChanges {
    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

    @Input()
    public type: string;

    @Input()
    public level: number;

    @Input()
    public sportId: number;

    @Input()
    public categoryId: number | null = null;

    @Input()
    public sportName: string;

    @Input()
    private cache: Record<string, any>;

    @Input()
    public fulltext: string = '';

    @Input()
    public level2: NaviLevel2Interface[] | null = null;

    @Input()
    public alphaFilter: { star: boolean; alpha: string[] };

    @Input()
    public year: string;

    @Input()
    private view: string = 'topNav';

    @Output()
    private onload: EventEmitter<{
        sportId?: number;
        categoryId?: number | null;
        data: NaviLevel2Interface | null;
    }> = new EventEmitter();

    public swiperConfig: SwiperOptions = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        direction: 'horizontal',
        watchOverflow: false,
        slidesPerView: 4,
        loop: false,
    };

    public loadingLevel2: boolean = false;

    public isMobile: boolean = false;

    public secondNaviLoaded: number | null = null;

    private first: boolean = true;

    public data: NaviLevel1Interface[] | NaviLevel2Interface[];

    /**
     * Cache level 2 data
     */
    public cacheData: { [prop: number]: NaviLevel2Interface };

    private subscriber: Subscription;

    public tournamentUrl = '/page/sport/event';

    public categoryUrl?: string = '/page/sport/category';

    public constructor(
        private navi: NavigationService,
        private favorite: FavoriteService,
        private nav: NavCompetitionService,
        private deviceService: DeviceDetectorService,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.start();
        this.reset();
        this.isMobile = this.deviceService.isMobile();

        if (this.isStage()) {
            this.tournamentUrl = '/page/stage/event';
        } else if (this.sportName === 'golf') {
            this.tournamentUrl = '/page/golf/tournament';
        }

        if (this.sportId) {
            if (this.type !== 'selected' && ((this.level === 2 && this.isMobile) ||
            !(this.level === 2 && this.type !== 'top'))) {
                this.reset();
                timer(500, 500)
                    .pipe(take(1))
                    .subscribe((): void => {
                        this.loadNav();
                    });
            }
        } else {
            this.onload.emit({
                data: null,
            });
        }

        if (this.level === 1) {
            this.cacheData = {};
        }

        if (this.level === 2 && this.isMobile) {
            this.loadingLevel2 = true;
        }

        if (this.level === 2 && this.type === 'top') {
            this.favorite.onToggleTournament.subscribe((): void => {
                this.loadNav();
            });
        }

        if (this.isMobile) {
            this.swiperConfig.slidesPerView = 2;
            this.swiperConfig.watchOverflow = true;
        }

        // this.nav.onReset.subscribe(() => {
        //   if (!this.first) {
        //     this.reset();
        //     this.loadNav();
        //   }
        // });

        this.first = false;
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
    }

    /**
     * on open reset all fitlers
     * @return {void}
     */
    public resetNav(): void {
        $$('.dropdown-menu--megamenu').toggleClass(
            'dropdown-menu--megamenu--active',
        );
        $$('body').toggleClass('megamenu-open');
        $$('body').toggleClass('lock-scroll');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.type === 'selected') {
            this.loadNav();
        } else if (
            !_.isUndefined(changes.sportId) &&
            !_.isUndefined(changes.sportId.previousValue) &&
            changes.sportId.previousValue !== changes.sportId.currentValue
        ) {
            this.reset();
            this.loadNav();
        }
        if (!_.isUndefined(changes.year) && changes.year.previousValue !== undefined &&
        changes.year.previousValue !== changes.year.currentValue) {
            this.loadNav();
        }
    }

    public imageRep(img: string): string {
        if (_.isNull(img)) {
            return '';
        }
        return img.replace('1x1', '4x3');
    }

    /**
     * Event on Level 2 data load, cache data
     * @event NavigationComponent#dataLoad
     * @param  {sportId: number;categoryId: number;
     *  data: NaviLevel2Interface;} - data
     * @return  {void}
     */
    private dataLoad(data: any): void {
        if (data.data.length > 0) {
            this.cacheData[data.categoryId] = data.data;
        }

        this.secondNaviLoaded = null;
    }

    public trackByFn(index: number, item: any): number {
        return item.id;
    }

    /**
     * Check if search result has some result
     * @return {boolean}
     */
    public get noSearchResult(): boolean {
        if (!this.fulltext || this.fulltext.length < 3) {
            return false;
        }
        const parent = $('.app-navi-parent:visible');

        return parent.length as number === 0;
    }

    public hasNoChild(id: number): boolean {
        if (!this.fulltext || this.fulltext.length < 3) {
            return false;
        }
        const parent = $(`#app-nav-${id}`);
        const child = $(`.app-nav-${id}`);

        return !!(
            this.secondNaviLoaded !== id &&
            this.fulltext &&
            this.fulltext.length >= 3 &&
            parent.find(child).length as number === 0
        );
    }

    /**
     * [mobileOpenSecodnLevel description]
     * @param $even
     */
    public mobileOpenSecondLevel(event: any, nav: NaviLevel1Interface): void {
        if (!this.isMobile) {
            return;
        }

        nav.active = !nav.active;
        // event.preventDefault();
        // event.stopPropagation()
    }

    /**
     * Cloase all navi
     */
    public closeAll(): void {
        this.data.forEach((val: any): void => { (val as NaviLevel1Interface).active = false; });
    }

    /**
     * Get navigation data
     */
    private async loadNav(): Promise<any> {
        try {
            this.loaded = false;
            let nav;

            if (this.level === 2) {
                if (this.level2 !== null) {
                    nav = of(this.level2);
                } else if (
                    this.type !== 'top' &&
                    this.cache &&
                    !_.isUndefined(this.cache[this.categoryId!])
                ) {
                    nav = of(this.cache[this.categoryId!]);
                } else {
                    if (this.type === 'all') {
                        this.type = _.isUndefined(
                            // @ts-ignore
                            SPORT_CONFIG.navigation.child[this.sportName],
                        )
                            ? this.type
                            // @ts-ignore
                            : SPORT_CONFIG.navigation.main[this.sportName];
                    }
                    const favorites =
                        this.type === 'top'
                            ? this.favorite.getTournamentFavorites(this.sportId)
                            : [];
                    nav = await this.navi.getNavLevel2(
                        this.sportId,
                        this.categoryId,
                        this.type,
                        favorites,
                        SPORT_CONFIG.isStage(this.sportName),
                    );

                    if (this.type === 'top') {
                        nav = this.filterFavorites(nav as Observable<NaviLevel2Interface[]>);
                    }
                }
            } else {
                this.cache = {};

                const typeVariant =
                    this.type === 'selected' ? 'all' : this.type;
                const type = _.isUndefined(
                    // @ts-ignore
                    SPORT_CONFIG.navigation.main[this.sportName],
                )
                    ? typeVariant
                    // @ts-ignore
                    : SPORT_CONFIG.navigation.main[this.sportName];
                const favorites =
                    this.type === 'top'
                        ? this.favorite.getTournamentFavorites(this.sportId)
                        : [];

                const limit = SPORT_CONFIG.isMma(this.sportName) ? 30 : 100;
                nav = await this.navi.getNavLevel1(
                    this.sportId,
                    type,
                    limit,
                    favorites,
                    SPORT_CONFIG.isStage(this.sportName),
                    true,
                );
                if (this.type === 'selected') {
                    nav = (nav as Observable<NaviLevel1Interface[]>).pipe(
                        map((val: any): any[] => _.filter(
                            val,
                            (data: any): boolean => data.id === this.categoryId,
                        ),
                        ),
                    );
                }
            }

            this.subscriber = (nav as Observable<any>).subscribe(
                (data): void => {
                    this.data = data;

                    this.finished();
                    this.loadingLevel2 = false;
                    if (this.level === 2) {
                        if (this.type === 'top') {
                            _.each(this.data as any, (val: any): void => {
                                if (
                                    !this.favorite.existsTournament(
                                        this.sportId,
                                        val.id,
                                    )
                                ) {
                                    this.favorite.toggleTournamnetFavorite(
                                        this.sportId,
                                        val.id,
                                        false,
                                    );
                                }


                                if (
                                    this.favorite.existsTournament(
                                        this.sportId,
                                        val.id,
                                    ) && val.season_to
                                ) {
                                    const seasonTo = moment(val.season_to_max).add(2, 'days');

                                    if (seasonTo.isBefore(moment()) && !val.favorite && !val.favorite_sctick) {
                                        this.favorite.toggleTournamnetFavorite(
                                            this.sportId,
                                            val.id,
                                            false,
                                            true,
                                        );
                                    }
                                }
                            });

                            setTimeout((): void => {
                                if (!_.isUndefined(this.swiper)) {
                                    this.swiper.swiperRef.update();
                                }
                            }, 500);
                        }
                        this.onload.emit({
                            sportId: this.sportId,
                            categoryId: this.categoryId,
                            data,
                        });
                    }
                },
                (): void => {
                    this.error();
                },
            );
        } catch (e) {
            this.error();
        }
    }

    /**
     * Merge new data from server with manually edited favorites
     * @param  {Observable<NaviLevel2Interface[]>} nav
     * @return  {Observable<NaviLevel2Interface[]}
     */
    private filterFavorites(
        nav: Observable<NaviLevel2Interface[]>,
    ): Observable<NaviLevel2Interface[]> {
        return nav.pipe(
            map((val: any): any[] => _.filter(
                val,
                (data: any): boolean => this.favorite.existsTournament(this.sportId, data.id) ||
                        !this.favorite.existsTournamentClicked(
                            this.sportId,
                            data.id,
                        ) ||
                        data.favorite_strick,
            ),
            ),
        );
    }

    public isStage(): boolean {
        return SPORT_CONFIG.isStage(this.sportName);
    }
}
