/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NavCompetitionService } from 'src/app/services/nav-competitions.service';
import { MatchListService } from 'src/app/services/match-list.service';
import { LangService } from 'src/app/services/lang.service';
import * as _ from 'underscore';
import { ClientService } from '@services/client.service';

import ALPHA from './alpha-codes';

@Component({
    selector: 'app-alpha-filter',
    templateUrl: './alpha-filter.component.html',
})
export class AlphaFilterComponent implements OnInit {
    public codes: { text: string; active: boolean }[] = [];

    public starActive: boolean = false;

    public show: boolean = true;

    @Input()
    public availLetters: string[] | null = null;

    @Input()
    public showStar: boolean = true;

    @Output()
    public onDataChange = new EventEmitter<{
            star: boolean;
            alpha: string[];
        }>();

    public constructor(
        private nav: NavCompetitionService,
        private match: MatchListService,
        private lang: LangService,
        public client: ClientService,
    ) {}

    public ngOnInit(): void {
        this.lang.getLang().then((iso): void => {
            let data = [];
            if (_.isUndefined(ALPHA[iso as 'en' | 'cs' | 'ru'])) {
                data = ALPHA.en;
            } else {
                data = ALPHA[iso as 'en' | 'cs' | 'ru'];
            }

            data.forEach((val): void => {
                const ob: any = {};
                ob.text = val;
                ob.active = false;
                this.codes.push(ob);
            });

            if (iso === 'el') {
                this.show = false;
            }
        });

        this.nav.onReset.subscribe((): void => {
            this.reset().change();
        });

        this.match.alphaReset.subscribe((): void => {
            this.reset();
            this.change();
        });
    }

    /**
     * Check if letter is avail
     * @return [description]
     */
    public isAlphaAvail(letter: string): boolean {
        if (this.availLetters === null) {
            return true;
        }

        return this.availLetters.includes(letter);
    }

    /**
     * Toggle code
     * @param code
     * @return {void}
     */
    public activeCode(code: { text: string; active: boolean }): void {
        if (this.isAlphaAvail(code.text)) {
            this.starActive = false;
            code.active = !code.active;
            this.change();
        }
    }

    public reset(onlyAlpha: boolean = false): AlphaFilterComponent {
        if (!onlyAlpha) {
            this.starActive = false;
        }
        this.codes = this.codes.map((val): {
            text: string;
            active: boolean;
        } => {
            val.active = false;
            return val;
        });
        return this;
    }

    /**
     * On data change evet
     * @event AlphaFilterComponent#chnage
     * @return {void}
     */
    public change(): void {
        const ob: { star: boolean; alpha: string[] } = {
            star: false,
            alpha: [],
        };

        ob.star = this.starActive;

        this.codes.forEach((val): void => {
            if (val.active) { ob.alpha.push(val.text); }
        });

        setTimeout((): void => this.onDataChange.emit(ob), 100);
    }
}
