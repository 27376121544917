import { Component, OnInit, Input, EventEmitter, Output, NgZone } from '@angular/core';
import {
    SocialAuthService as AuthService,
    FacebookLoginProvider,

} from '@abacritt/angularx-social-login';

import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { SocialSignInInterface } from 'src/app/interfaces/social-signin.interface';
import { Observable, Subscription, take } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
// eslint-disable-next-line import/no-extraneous-dependencies
import jwtDecode from 'jwt-decode';
import { Select } from '@ngxs/store';

import { UserState } from '@/store/states/user.state';
import { UserInterface } from '@/interfaces/user.interface';
import { WindowRefService } from '@/shared/window-ref';
import { FormErrorInterface, FormResponseInterface } from '@/interfaces/form-response.interface';

// declare const dataLayer: any;
declare const google: any;
@Component({
    selector: 'app-social-sign-in',
    templateUrl: './social-sign-in.component.html',
})
export class SocialSignInComponent implements OnInit {
    @Select(UserState.data) public user$: Observable<UserInterface>;

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onChange: EventEmitter<string> = new EventEmitter();

    @Input()
    public type: string;

    @Input()
    public idName: string = '';

    public signError: boolean;

    public progress: boolean = false;

    // eslint-disable-next-line no-useless-constructor
    public constructor(
        public user: UserService,
        private authService: AuthService,
        private router: Router,
        private deviceService: DeviceDetectorService,
        private ngZone: NgZone,
        private win: WindowRefService,
    ) {}

    public ngOnInit(): void {
        this.ngZone.runOutsideAngular((): void => {
            setTimeout(() => {
                google.accounts.id.initialize({
                    client_id: '768818760475-bahpghpmf3ptgnivkib418tbga724s57.apps.googleusercontent.com',


                    callback: (response: any) => {
                        // eslint-disable-next-line no-console
                        // console.log(`Encoded JWT ID token: ${response.credential}`, response);

                        const responsePayload: SocialSignInInterface =
                            jwtDecode(response.credential) as SocialSignInInterface;
                        responsePayload.provider = 'GOOGLE';
                        // console.log('Decoded', responsePayload);
                        this.sendCredentialsToServer(responsePayload as SocialSignInInterface, 'google');
                        this.onChange.emit('close');
                    },
                });
                google.accounts.id.renderButton(
                    document.getElementById(`buttonDiv${this.idName}`),
                    { theme: 'outline', size: 'large' }, // customization attributes
                );
                google.accounts.id.prompt(); // also display the One Tap dialog
            }, 1000);
        });

        this.signError = false;
        this.authService.authState.subscribe(
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            (_user): void => {
                //   console.log('User:', user);
            },
            (er): void => {
                console.error('Social sign in ERROR', er);
                this.reset();
            },
        );
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    /**
     * Facebook sign in
     */
    public signInWithFB(): void {
        this.signIn(FacebookLoginProvider.PROVIDER_ID);
    }


    /**
     * Sign in via social plugin
     * @param  {string} providerId
     * @return  {void}
     */
    private signIn(providerId: string): void {
        this.signError = false;
        this.progress = true;
        this.authService.signIn(providerId).then(

            (resp): Subscription => this.sendCredentialsToServer(resp as SocialSignInInterface, 'facebook'),
            (_e): void => {
                this.signError = true;
                this.reset();
            },
        );
    }

    private sendCredentialsToServer(respData: SocialSignInInterface, provider: string): Subscription {
        return this.user.signIn<SocialSignInInterface>(respData as SocialSignInInterface, true).subscribe(
            (resp: FormResponseInterface | FormErrorInterface): Promise<any> => {
                this.user$.pipe(take(1)).subscribe((user) => {
                    if (resp && 'first' in resp && resp.first) {
                        this.win.nativeWindow.dataLayer.push({
                            event: 'sign_up',
                            email: respData.email,
                            type: 'manual',
                        });
                    }
                    this.win.nativeWindow.dataLayer.push({
                        event: 'login',
                        email: user.email,
                        type: provider,
                    });
                });
                return this.user.getStatus().then((): void => {
                    this.onChange.emit('close');
                    this.reset();
                });
            },
            (): void => {
                this.signError = true;
                this.reset();
            },
        );
    }

    private reset(): void {
        this.progress = false;
    }
}
